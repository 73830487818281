
import { defineComponent, ref } from "@vue/runtime-core"

export default defineComponent({
  name: 'dropdown',
  emits: ['dropdownClick'],
  props: {
    onDropdownClick: Function
  },
  setup(props, { emit }) {
    const activeName = ref('全部')
    const statusList: Array<string> = ['全部', '未开始', '进行中', '已结束']

    function dropdownClick(status: string): void {
      activeName.value = status
      emit('dropdownClick', status)
    }

    return {
      activeName,
      statusList,
      dropdownClick
    }
  }
})

