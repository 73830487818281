import api from "@/api";
import { ListOpions } from "@/models/type";
import { parseTime } from "@/utils";
import { Options, Vue } from "vue-property-decorator";
import dropdown from './components/dropdown.vue'
import './index.scss'
@Options({
  components: { dropdown }
})
export default class MineSubject extends Vue {
  private subjectList: Array<any> = []

  public status = '' as string | number
  public statusName = ''

  public pageNum = 1
  public pageSize = 10
  public total = 0

  public statusList: Array<ListOpions> = [
    { name: '未开始', value: 0 },
    { name: '进行中', value: 1 },
    { name: '已开始', value: 2 },
    { name: '已结束', value: 3 }
  ]

  listCell(): JSX.Element {
    return <> {this.subjectList.map(item => {
      return <el-col
        xs={24}
        sm={12}
        md={8}
        lg={6}
        xl={4}
      >
        <div class="mine_subject-cell pointer" onClick={() => this.goDetail(item.id)}>
          <img src={item.coverImg} alt="" />
          <div class="mine_subject-info">
            <h4 class="mine_subject-title">课程简介</h4>
            <p class="mine_subject-content">{item.introduce}</p>
          </div>
          <div class="mine_subject-bottom">
            <span class="mine_subject-time">
              {parseTime(new Date(item.signUpStartTime).getTime(), '{m}月{d}日')}
                -
              {parseTime(new Date(item.signUpEndTime).getTime(), '{d}日')}
            </span>
            <span class="mine_subject-status">{this.statusList.find(i => i.value === item.status)?.name}</span>
          </div>
        </div>
      </el-col>
    })}</>
  }

  goDetail(id: number): void {
    if(document.body.clientWidth < 900) {
      // 跳转的mobile
      window.location.href = `http://mobile.taosseract.com/pages/subject/detail?id=${id}`

    } else {
      this.$router.push({
        name: 'SubjectDetail',
        params: { id }
      })
    }
  }

  getList(): void {
    api.getOwnSubjectOrActivity({
      pageSize: this.pageSize,
      pageNum: this.pageNum,
      type: 'subject',
      status: this.status }).then(res => {
      this.subjectList = res.data.list
      this.pageNum = res.data.pageNum
      this.pageSize = res.data.pageSize
      this.total = res.data.total
    })
  }

  dropdownClick(status: string): void {
    switch (status) {
      case '全部':
        this.status = ''
        break
      case '未开始':
        this.status = 0
        break
      case '进行中':
        this.status = 1
        break
      case '已开始':
        this.status = 2
        break
      case '已结束':
        this.status = 3
        break
      default:
        this.status = ''
        break
    }
    this.getList()
  }

  handleCurrentChange(pageNum: number): void {
    this.pageNum = pageNum
    this.getList()
  }

  render(): JSX.Element {
    return <div class="mine_subject">
      <div class="mine_subject-block">
        <dropdown onDropdownClick={this.dropdownClick} />
      </div>
      <el-row gutter={40}>
        {this.subjectList.length ? this.listCell() : <div>没有参加任何课程</div>}
      </el-row>
      <el-pagination
        class="mine_subject-pagination"
        onCurrentChange={this.handleCurrentChange}
        currentPage={this.pageNum}
        pageSize={this.pageSize}
        layout="prev, pager, next, total"
        total={ this.total }
      />
    </div>
  }

  mounted(): void {
    this.getList()
  }
}
